export const inquiryTicketBody = (request) => {
  let { fullName, email, emailBody, subject, typeOfInquiry } = request

  return `Name: ${fullName}
  Email: ${email}
  Subject: ${subject}
  Type of inquiry: ${typeOfInquiry}
  Message: 
  ${emailBody} 
  `
}
