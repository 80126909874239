import React from "react"

const HelpCenterTable = ({ data }) => (
  <table className="table is-fullwidth">
    <thead>
      <tr>
        {data?.headers?.map((header) => (
          <th>{header}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data?.rows?.map((row) => (
        <tr>
          {row?.columns?.map((column, index) => (
            <td
              width={`${100 / data?.rows?.length}%`}
              rowspan={row?.rowspan && row?.rowspan[index]}
              dangerouslySetInnerHTML={{ __html: column }}
            />
          ))}
        </tr>
      ))}
    </tbody>
  </table>
)

export default HelpCenterTable
